<template>
  <section class="bg-blue-light pad-y-60 text-center">
    <div class="container">
      <div class="heading" data-aos="fade-down">
        <h2 class="title font-secondary text-teal-dark">
          Assalamualaikum Warahmatullahi Wabarakatuh
        </h2>
      </div>

      <p
        class="font-weight-lighter font-italic mar-bottom-25"
        data-aos="fade-right"
        data-aos-delay="300"
      >
        Dengan segala kerendahan hati dan penuh syukur atas karunia Allah SWT, Kami mengundang Bapak/Ibu/Saudara/i untuk menghadiri Acara Pernikahan kami yang akan diselenggarakan pada:
      </p>

      <div class="row py-3">
        <div class="col-lg-6">
          <Event
            :location="{ address: $parent.location }"
            image="https://images.unsplash.com/photo-1515934751635-c81c6bc9a2d8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
            start="2021-05-25 14:00"
            end="Selesai"
            title="Akad"
            data-aos="fade-right"
            data-aos-delay="300"
          />
        </div>
        <div class="col-lg-6">
          <Event
            :location="{ address: $parent.location }"
            image="https://images.unsplash.com/photo-1529636120425-66f3708694e7?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
            start="2021-05-25 18:30"
            end="21:00"
            title="Resepsi"
            data-aos="fade-left"
            data-aos-delay="300"
          />
        </div>
      </div>

      <p
        class="font-weight-lighter font-italic"
        data-aos="fade-left"
        data-aos-delay="600"
      >
        Merupakan suatu kehormatan dan kebahagiaan bagi kami apabila
        Bapak/Ibu/Saudara/i berkenan hadir dan memberikan doa restu kepada
        putra-putri kami. Atas kehadiran serta doa restu Bapak/Ibu/Saudara/i,
        kami sekeluarga mengucapkan terima kasih. Kami yang berbahagia.
      </p>

      <h3
        class="font-secondary text-teal-dark mb-0 mar-top-25"
        data-aos="fade-up"
        data-aos-delay="700"
      >
        {{ $parent.wedding.man.surname }} & {{ $parent.wedding.woman.surname }}
      </h3>
    </div>
  </section>
</template>

<script>
import Event from "./Event.vue";
export default {
  components: { Event },
};
</script>