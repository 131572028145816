<template>
  <div class="maps" :style="{ height: `${height}px` }">
    <div class="omnibox" :class="{ collapsed: collapsed }" ref="omnibox">
      <div class="omnibox-header">
        <h4>Location</h4>
        <button class="btn-collapse" @click="toggleCollapse">
          <i class="material-icons">{{ icon }}</i>
        </button>
      </div>

      <div class="omnibox-body py-2">
        <div class="section-info padding">
          <span class="icon">
            <i class="material-icons">calendar_today</i>
          </span>
          <span class="content">
            {{ time.start.format("dddd, DD MMMM YYYY") }}
          </span>
        </div>
        <div class="section-info padding">
          <span class="icon">
            <i class="material-icons">access_time</i>
          </span>
          <span class="content">
            {{ time.start.format("HH.mm") }} - {{ time.end }}
          </span>
        </div>
        <div class="section-info padding">
          <span class="icon">
            <i class="material-icons">place</i>
          </span>
          <span class="content" v-html="location">
          </span>
        </div>

        <div class="padding mt-2">
          <a
            class="btn btn-primary btn-block has-icon"
            target="_blank"
            :href="directionUrl"
          >
            <i class="material-icons">directions</i>
            Direction
          </a>
        </div>
      </div>
    </div>
    <iframe
      :src="url"
      width="100%"
      :height="height"
      frameborder="0"
      style="border: 0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  props: ["height", "url", "location", "directionUrl", "date", "time"],
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {
    icon() {
      return this.collapsed ? "arrow_right" : "arrow_left";
    },
  },
  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
  },
  mounted() {
    this.collapsed = this.$refs.omnibox.offsetWidth * 2 > window.innerWidth;
  },
};
</script>
